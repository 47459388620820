import React, { Component } from "react";
import {} from "react-bootstrap";

class NotFoundComponent extends Component {
  render() {
    return <h1>NotFoundComponent.js</h1>;
  }
}

export default NotFoundComponent;
